<!--
 * @Author: ch3nh2
 * @Date: 2021-11-14 15:17:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-04-12 16:51:43
 * @FilePath: /zhy_dataview_fe/src/pages/meteorology.vue
 * @Description: 水文气象--详情
-->
<template>
  <div class="meteorology container">
    <HomeHead />
    <div class="meteorology-content">
      <div class="meteorology-menu">
        <div class="meteorology-menu-left" @click="onBack">
          <el-icon>
            <arrow-left-bold />
          </el-icon>
          <span>返回主页面</span>
        </div>
        <div class="meteorology-menu-right">
          <el-form :inline="true" :model="formInline" class="form-inline">
            <el-form-item>
              <el-date-picker v-model="formInline.notifyTimeBegin" :editable="false" type="date" placeholder="选择开始日期" value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item label="至" class="item-split"></el-form-item>
            <el-form-item>
              <el-date-picker v-model="formInline.notifyTimeEnd" :editable="false" type="date" placeholder="选择结束日期" value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchEvent">查 询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="meteorology-item" v-for="(item, index) in itemArr" :key="index">
        <div class="meteorology-item-title">
          <div class="meteorology-item-icon">
            <img :src="item.icon" :alt="item.name" class="image" />
          </div>
          <div class="meteorology-item-name">{{ item.name }}</div>
        </div>
        <apexchart type="area" height="200" :ref="'apexchart' + index" :options="chartOptions(index)" :series="item.series" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "components/HomeHead";
import utils from "@/utils";
import { ArrowLeftBold } from "@element-plus/icons";
export default {
  name: "Meteorology",
  data() {
    return {
      itemArr: [
        {
          name: "温度",
          icon: require("../assets/image/pages/meteorology/icon-01.png"),
          series: [
            {
              name: "当前指数",
              data: [],
            },
          ],
        },
        {
          name: "湿度",
          icon: require("../assets/image/pages/meteorology/icon-02.png"),
          series: [
            {
              name: "当前指数",
              data: [],
            },
          ],
        },
        {
          name: "气压",
          icon: require("../assets/image/pages/meteorology/icon-03.png"),
          series: [
            {
              name: "当前指数",
              data: [],
            },
          ],
        },
        {
          name: "风力方向",
          icon: require("../assets/image/pages/meteorology/icon-04.png"),
          series: [
            {
              name: "当前指数",
              data: [],
            },
          ],
        },
        {
          name: "流速",
          icon: require("../assets/image/pages/meteorology/icon-05.png"),
          series: [
            {
              name: "当前指数",
              data: [],
            },
          ],
        },
      ],
      formInline: {
        notifyTimeBegin: utils.getCurrentMonthFirst(),
        notifyTimeEnd: utils.getCurrentMonthLast(),
      },
    };
  },
  computed: {
    chartOptions() {
      let colors = [];
      return (i) => {
        switch (i) {
          case 0:
            colors = ["#0e9cff"];
            break;
          case 1:
            colors = ["#ffb70b"];
            break;
          case 2:
            colors = ["#09a049"];
            break;
          case 3:
            colors = ["#0f9fce"];
            break;
          case 4:
            colors = ["#00e3d0"];
            break;
          default:
            break;
        }
        return {
          colors,
          chart: {
            type: "area",
            background: "#1f1f2b",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          grid: {
            show: false,
          },
          stroke: {
            width: 2,
          },
          xaxis: {
            type: "category",
            categories: [],
            range: 10 - 1,
            labels: {
              hideOverlappingLabels: true,
              trim: true,
            },
          },
          noData: {
            text: "当前暂无数据",
            offsetY: -15,
            style: {
              color: "#ffffff",
            },
          },
        };
      };
    },
  },
  components: {
    HomeHead,
    ArrowLeftBold,
  },
  props: {},
  methods: {
    getWeatherDetails() {
      this.$api
        .getWeatherDetails({
          beginTime: this.formInline.notifyTimeBegin.replace(/\//g, "-"),
          endTime: this.formInline.notifyTimeEnd.replace(/\//g, "-"),
        })
        .then((res) => {
          const { data } = res;
          let categories = [];
          let temperatureArr = [];
          let humidityArr = [];
          let airPressureArr = [];
          let windDirectionArr = [];
          let windSpeedArr = [];
          if (data) {
            data.map((item) => {
              categories.push(item.date);
              temperatureArr.push(item.temperature);
              humidityArr.push(item.humidity);
              airPressureArr.push(item.airPressure);
              windDirectionArr.push(item.windDirection);
              windSpeedArr.push(item.windSpeed);
            });
            this.itemArr[0].series[0].data = temperatureArr;
            this.itemArr[1].series[0].data = humidityArr;
            this.itemArr[2].series[0].data = airPressureArr;
            this.itemArr[3].series[0].data = windDirectionArr;
            this.itemArr[4].series[0].data = windSpeedArr;
            for (let i = 0; i < this.itemArr.length; i++) {
              this.$refs["apexchart" + i].updateOptions({
                xaxis: { categories },
              });
            }
          }
        });
    },
    onBack() {
      this.$router.push({ path: "/" });
    },
    searchEvent() {
      if (!this.formInline.notifyTimeBegin) {
        this.$utils.errorAlert("请先选择开始日期");
        return;
      }
      if (!this.formInline.notifyTimeEnd) {
        this.$utils.errorAlert("请先选择结束日期");
        return;
      }
      this.getWeatherDetails();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getWeatherDetails();
    });
  },
  created() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.meteorology {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1920px;
  .meteorology-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    margin: 0 10px;
    .meteorology-menu-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 15px;
      width: 100px;
      height: 40px;
      background-color: #424b60;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      &:active {
        opacity: 0.5;
      }
    }
    .meteorology-menu-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .meteorology-content {
    height: 100%;
    overflow: scroll;
    .meteorology-item {
      margin: 20px 10px;
      min-height: 225px;
      border: 1px #444b60 solid;
      .meteorology-item-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        background-color: #242632;
        .meteorology-item-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
          width: 20px;
          height: 20px;
        }
        .meteorology-item-name {
          color: #ffffff;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
